import { AxiosError, AxiosResponse } from 'axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getModule, Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { store } from '@/store';
import {
	ICountry,
	IPrices,
	IMetaData,
	JsonLD,
	IPartner,
	IPartnerLocation,
	IDataPerBu
} from '~/types/misc_data';
import { $axios } from '~/utils/api';

import { getBookingStartingPrice } from '@/_helpers/misc_helper';
import data_per_bu from '@/_helpers/data_per_bu';
import package_data from '@/_helpers/package_data';

import { ErrorResponse, stringifyExpands } from '~/types/api_helper';
import Basedata from '~/types/basedata';
import { TruckMinPrices } from '~/types/truck';

interface StringIndex {
	[key: string]: any;
}

@Module({
	name: 'MISC_DATA',
	store, // this basically injects the module in the store dynamically thanks to next line
	dynamic: true,
	stateFactory: true // apparently necessary/better for Nuxt
})
class MISC_DATA extends VuexModule {
	current_domain = '';

	countries = [] as ICountry[];

	data_per_bu = {} as IDataPerBu;

	current_locales = [] as { label: string; locale: string; flag_file_name: string }[];

	base_url = {} as { [index: string]: any; at: string; de: string; cz: string; hu: string };

	prices = {} as IPrices;
	ltr_prices = {};
	currency = '';
	features = {} as any;

	titles = [
		{ title: 'Mr', code: 'M' },
		{ title: 'Ms', code: 'F' }
	];

	package_data = package_data as StringIndex;

	partners = [] as IPartner[];
	press_list = [] as any[];
	media_articles = [] as any[];
	landing_pages = [] as any[];
	jobs = [] as any[];
	faq = [] as any[];

	current_LP_data = null as any;
	LP_map_data = {} as { lat: number; lng: number; zoom: number; zoom_mobile: number | null };

	basedata = new Basedata();
	truck_min_price = {
		L: {
			price_24: 0,
			price_6: 0,
			currency: 'EUR'
		},
		XL: {
			price_24: 0,
			price_6: 0,
			currency: 'EUR'
		},
		XXL: {
			price_24: 0,
			price_6: 0,
			currency: 'EUR'
		}
	} as TruckMinPrices;

	truck_min_price_per_location = {
		L: {
			price_24: 0,
			price_6: 0,
			currency: 'EUR'
		},
		XL: {
			price_24: 0,
			price_6: 0,
			currency: 'EUR'
		},
		XXL: {
			price_24: 0,
			price_6: 0,
			currency: 'EUR'
		}
	} as TruckMinPrices;

	meta_data = {} as any;

	jsonld = null as any;

	html_header_base_scripts = [
		// {
		// 	src: 'https://accounts.google.com/gsi/client',
		// 	defer: true,
		// 	async: true
		// },
		{
			vmid: 'gtm_helpers',
			id: 'gtmHelper',
			innerHTML: `
				updateGtmHelpers();
				function updateGtmHelpers() {

				if (!window.gtm_helpers){
					window.gtm_helpers = {
					"gclid": undefined,
					"ga_client_id_cookie": undefined,
					"client_id": undefined,
					"msclkid": undefined,
					"sznclid" : undefined,
				}
				}
				const urlParams = new URLSearchParams(window.location.search);

				const clickIdentifiers = {
					gclid: urlParams.get("gclid"),
					sznclid: urlParams.get("sznclid"),
					msclkid: urlParams.get("msclkid"),
					wbraid: urlParams.get("wbraid"),
					gbraid: urlParams.get("gbraid"),
					fbclid: urlParams.get("fbclid"),
				};

				for (identifier in clickIdentifiers) {
					let dlName = \`session_\${identifier}\`;
					let sessionName = \`\${identifier}_session\`;
					if (clickIdentifiers[identifier]) {
					if (window.dataLayer) {
						dataLayer.push({ dlName: clickIdentifiers[identifier] });
					}
					window.sessionStorage.setItem(sessionName, clickIdentifiers[identifier]);
					window.gtm_helpers[identifier] = clickIdentifiers[identifier];
					} else {
					let sessionValue = sessionStorage.getItem(sessionName);
					if (sessionValue) {
						clickIdentifiers[identifier] = sessionValue;
						if (window.dataLayer) {
						dataLayer.push({ dlName: clickIdentifiers[identifier] });
						}
						window.gtm_helpers[identifier] = clickIdentifiers[identifier];
					} else {
					}
					}
				}

				var cookie = {};
				document.cookie.split(";").forEach(function (el) {
					var splitCookie = el.split("=");
					var key = splitCookie[0].trim();
					var value = splitCookie[1];
					cookie[key] = value;
				});
				
				if(cookie["_ga"] !== undefined){
					window.gtm_helpers.client_id = cookie["_ga"].substring(6);
				}
				if(cookie["_ga"] !== undefined){
					window.gtm_helpers.ga_client_id_cookie = cookie["_ga"];
				}
			}`
		}
	] as any;

	html_header_prod_scripts = [].concat(this.html_header_base_scripts, [
		{
			vmid: 'gtm',
			id: 'gtmScript',
			innerHTML: `(function(w,d,s,l,i){
				w[l]=w[l]||[];
				for(const e of w[l]){
					if(e.hasOwnProperty('gtm.start')) return;
				}
				w[l].push({'gtm.start':	new Date().getTime(),event:'gtm.js'});
				var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),
					dl=l!='dataLayer'?'&l='+l:'';
				j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
				var n = d.querySelector('script[nonce]');
				n && j.setAttribute('nonce', n.nonce || n.getAttribute('nonce'))
				f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${process.env.GOOGLE_GTM}');`
		},
		{
			vmid: 'convert_vars',
			id: 'convet_vars',
			innerHTML: `
				// fill in the values for the variables that you want to use
				var _conv_page_type = "";
				var _conv_category_id = "";
				var _conv_category_name = "";
				var _conv_product_sku = "";
				var _conv_product_name = "";
				var _conv_product_price = "";
				var _conv_customer_id = "";
				var _conv_custom_v1 = "";
				var _conv_custom_v2 = "";
				var _conv_custom_v3 = "";
				var _conv_custom_v4 = "";
			`
		},
		{
			vmid: 'convert_script',
			id: 'convertScript',
			src: '//cdn-4.convertexperiments.com/js/100411946-100412226.js'
		}
	] as any);

	// special scripts for DE, to handle cookie consent
	html_header_prod_scripts_de = [].concat(this.html_header_base_scripts, [
		{
			once: true,
			src: 'https://cloud.ccm19.de/app.js?apiKey=d0944348dbbb4f81368760120093a1b4deaab6c3a55fb569&amp;domain=66b1ee18b850eb0e070ed1f2',
			referrerpolicy: 'origin'
		},
		{
			vmid: 'gtm',
			id: 'gtmScript',
			innerHTML: `(function(w,d,s,l,i){
				w[l]=w[l]||[];
				for(const e of w[l]){
					if(e.hasOwnProperty('gtm.start')) return;
				}
				w[l].push({'gtm.start':	new Date().getTime(),event:'gtm.js'});
				var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),
					dl=l!='dataLayer'?'&l='+l:'';
				j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
				var n = d.querySelector('script[nonce]');
				n && j.setAttribute('nonce', n.nonce || n.getAttribute('nonce'))
				f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${process.env.GOOGLE_GTM}');`,
			type: 'text/x-ccm-loader',
			'data-ccm-loader-group': 'ccm-loader-gtm'
		},
		{
			vmid: 'convert_vars',
			id: 'convet_vars',
			type: 'text/x-ccm-loader',
			'data-ccm-loader-group': 'ccm-loader-convert',
			innerHTML: `
			// fill in the values for the variables that you want to use
			var _conv_page_type = "";
			var _conv_category_id = "";
			var _conv_category_name = "";
			var _conv_product_sku = "";
			var _conv_product_name = "";
			var _conv_product_price = "";
			var _conv_customer_id = "";
			var _conv_custom_v1 = "";
			var _conv_custom_v2 = "";
			var _conv_custom_v3 = "";
			var _conv_custom_v4 = "";
		`
		},
		{
			vmid: 'convert_script',
			id: 'convertScript',
			type: 'text/x-ccm-loader',
			'data-ccm-loader-group': 'ccm-loader-convert',
			src: '//cdn-4.convertexperiments.com/js/100411946-100412226.js'
		}
	] as any);

	// special scripts for CZ, to handle cookie consent
	html_header_prod_scripts_cz = [].concat(this.html_header_base_scripts, [
		{
			once: true,
			src: 'https://cloud.ccm19.de/app.js?apiKey=d0944348dbbb4f81368760120093a1b4deaab6c3a55fb569&amp;domain=674ef180ea1611560600b0b2',
			referrerpolicy: 'origin'
		},
		{
			vmid: 'gtm',
			id: 'gtmScript',
			innerHTML: `(function(w,d,s,l,i){
				w[l]=w[l]||[];
				for(const e of w[l]){
					if(e.hasOwnProperty('gtm.start')) return;
				}
				w[l].push({'gtm.start':	new Date().getTime(),event:'gtm.js'});
				var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),
					dl=l!='dataLayer'?'&l='+l:'';
				j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
				var n = d.querySelector('script[nonce]');
				n && j.setAttribute('nonce', n.nonce || n.getAttribute('nonce'))
				f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${process.env.GOOGLE_GTM}');`,
			type: 'text/x-ccm-loader',
			'data-ccm-loader-group': 'ccm-loader-gtm'
		},
		{
			vmid: 'convert_vars',
			id: 'convet_vars',
			type: 'text/x-ccm-loader',
			'data-ccm-loader-group': 'ccm-loader-convert',
			innerHTML: `
			// fill in the values for the variables that you want to use
			var _conv_page_type = "";
			var _conv_category_id = "";
			var _conv_category_name = "";
			var _conv_product_sku = "";
			var _conv_product_name = "";
			var _conv_product_price = "";
			var _conv_customer_id = "";
			var _conv_custom_v1 = "";
			var _conv_custom_v2 = "";
			var _conv_custom_v3 = "";
			var _conv_custom_v4 = "";
		`
		},
		{
			vmid: 'convert_script',
			id: 'convertScript',
			type: 'text/x-ccm-loader',
			'data-ccm-loader-group': 'ccm-loader-convert',
			src: '//cdn-4.convertexperiments.com/js/100411946-100412226.js'
		}
	] as any);

	chatling_script = {
		id: 'chatling-embed-script',
		'data-id': '2933327197',
		vmid: 'chatling_chat',
		src: 'https://chatling.ai/js/embed.js',
		async: true
	};

	// ------------------------------------------------
	// ------------- Mutations ------------------------
	// ------------------------------------------------

	@VuexMutation
	storeBasedata(data: any) {
		const bu = data.bu.toLowerCase();
		this.current_domain = bu;
		this.data_per_bu = (data_per_bu as any)[bu];
		this.partners = this.data_per_bu.partners;

		// store full object
		this.basedata = new Basedata(data);

		// Inject the countries svg path in the store countries
		this.countries = data.countries;

		const p_data = {} as StringIndex;
		for (const [key, value] of Object.entries(this.package_data as StringIndex)) {
			const pack = data.max_deductibles.filter(
				(item: any) => item.type.toLowerCase() === key
			)[0];
			value.coverage_max = pack.value;
			p_data[key] = value;
		}
		this.package_data = p_data;

		const prices = {} as any;
		data.prices.forEach((item: any) => {
			prices[item.type.toLowerCase()] = item.price;
			this.currency = item.currency;
		});
		// @TODO Dynamicaly calculates depending on features and promo prices
		this.prices = prices;
		prices.starting_price = getBookingStartingPrice(prices, data.features);

		this.features = data.features;
		this.ltr_prices = data.ltr;
	}

	@VuexMutation
	storeMinTruckPrice(data: any) {
		this.truck_min_price = data;
	}

	@VuexMutation
	storeMinTruckPricePerLocation(data: any) {
		this.truck_min_price_per_location = data;
	}

	@VuexMutation
	storeJsonLd(data: any) {
		this.jsonld = data;
	}

	@VuexMutation
	generateCurrentLocales(domain: string) {
		this.current_locales = [
			{
				label: 'Deutsch',
				locale: 'de-' + domain.toUpperCase(),
				flag_file_name: domain === 'at' ? 'at.svg' : 'de.svg'
			},
			{
				label: 'English',
				locale: 'en-' + domain.toUpperCase(),
				flag_file_name: 'gb.svg'
			},
			{
				label: 'Česky',
				locale: 'cs-' + domain.toUpperCase(),
				flag_file_name: 'cz.svg'
			},
			{
				label: 'Magyar',
				locale: 'hu-' + domain.toUpperCase(),
				flag_file_name: 'hu.svg'
			},
			{
				label: 'Slovak',
				locale: 'sk-' + domain.toUpperCase(),
				flag_file_name: 'sk.svg'
			}
		];
	}

	@VuexMutation
	storeJobList(data: any[]) {
		this.jobs = data;
	}

	@VuexMutation
	storeFaqList(data: any[]) {
		this.faq = data;
	}

	@VuexMutation
	storePressList(data: any[]) {
		this.press_list = data;
	}

	@VuexMutation
	storeMediaArticles(data: any[]) {
		this.media_articles = data;
	}

	@VuexMutation
	storeLandingPages(data: any[]) {
		this.landing_pages = data;
	}

	@VuexMutation
	storeCurrentLP(data: any) {
		this.current_LP_data = data;
		const center = data.map.center;

		this.LP_map_data = {
			lng: parseFloat(center.lng),
			lat: parseFloat(center.lat),
			zoom: parseFloat(data.map.zoom),
			zoom_mobile: data.map.zoom_mobile ? parseFloat(data.map.zoom_mobile) : null
		};
	}

	@VuexMutation
	storePartnerLocations(payload: { id: string; locations: IPartnerLocation[] }) {
		const partners = JSON.parse(JSON.stringify(this.partners));
		this.partners = partners.map((p: IPartner) => {
			if (p.id === payload.id) {
				p.locations = payload.locations;
			}
			return p;
		});
	}

	@VuexMutation
	storeBaseUrl(data: any) {
		this.base_url = data;
	}

	@VuexMutation
	addMetaData(params: { key: string; title: string; desc: string }) {
		const entry = new IMetaData(params.title, params.desc);
		this.meta_data[params.key] = entry;
	}

	// ------------------------------------------------
	// ------------- Actions --------------------------
	// ------------------------------------------------

	@VuexAction
	GENERATE_META_DATA(entries: any) {
		for (const [key, value] of Object.entries(entries)) {
			this.context.commit('addMetaData', {
				key: key,
				title: (value as any).title,
				desc: (value as any).desc
			});
		}
	}

	@VuexAction({ rawError: true })
	async GET_BASE_DATA(domain: string): Promise<any | ErrorResponse> {
		let url = `v2/basedata/${domain}`;
		url += stringifyExpands(['ltr']);

		console.log(`Request Basedata for ${domain}`); // test to check if its possible to see server logs
		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				(this as any).store.$logger.console({ message: `Basedata requested : '${url}` });
				this.context.commit('storeBasedata', res.data);
				this.context.commit('generateCurrentLocales', res.data.bu.toLowerCase());
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction
	STORE_JSON_LD(data: any): any {
		this.context.commit('storeJsonLd', data);
	}

	@VuexAction({ rawError: true })
	async GET_PARTNER_LOCATIONS(params: { partner_id: string; bu: string }): Promise<boolean> {
		const url = `/v2/basedata/${params.bu}/location-partner/${params.partner_id}`;

		try {
			const res = await $axios.get(url).then((res: AxiosResponse) => res.data);
			(this as any).store.$logger.console({
				message: `Partner Location requested : '${url}`
			});
			this.context.commit('storePartnerLocations', { id: params.partner_id, locations: res });
			return true;
		} catch (err) {
			console.error(`GET_PARTNER_LOCATIONS '${url}' failed with:`, err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async GET_JOB_LIST(domain: string): Promise<any> {
		return await $axios
			.get(`v2/basedata/${domain}/jobs`)
			.then((res: AxiosResponse) => {
				this.context.commit('storeJobList', res.data);
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_JOB_DETAIL(params: { domain: string; id: string }): Promise<any> {
		try {
			const res = await $axios
				.get(`v2/basedata/${params.domain}/jobs/${params.id}`)
				.then((res: AxiosResponse) => res.data);
			return res;
		} catch (err) {
			console.error('GET_JOB_DETAIL failed with:', err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async GET_FAQ_LIST(params: { domain: string; lang: string }): Promise<any> {
		const url = `v2/basedata/${params.domain}/faq?lang=${params.lang ?? 'de'}`;

		try {
			const res = await $axios.get(url).then((res: AxiosResponse) => res.data);
			this.context.commit('storeFaqList', res);
			return res;
		} catch (err: any) {
			console.error('GET_FAQ failed with: ', err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async GET_PRESS_LIST(domain: string): Promise<any> {
		try {
			const res = await $axios
				.get(`v2/basedata/${domain}/press/release`)
				.then((res: AxiosResponse) => res.data);
			this.context.commit('storePressList', res);
		} catch (err) {
			console.error('GET_PRESS_LIST failed with:', err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async GET_PRIVACY_POLICY(params: { domain: string; type?: string }): Promise<any> {
		params.type = params.type ?? 'default';

		return await $axios
			.get(`v2/basedata/${params.domain}/privacy-policy?type=${params.type}`)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_MEDIA_ARTICLES(domain: string): Promise<any> {
		try {
			const res = await $axios
				.get(`v2/basedata/${domain}/press/article`)
				.then((res: AxiosResponse) => res.data);
			this.context.commit('storeMediaArticles', res);
		} catch (err) {
			console.error('GET_MEDIA_ARTICLES failed with:', err);
		}
		return false;
	}

	@VuexAction({ rawError: true })
	async GET_LANDING_PAGE_LIST(domain: string): Promise<any | ErrorResponse> {
		const url = `v2/basedata/${domain}/landing-page`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				this.context.commit('storeLandingPages', res.data);
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_LANDING_PAGE(params: { domain: string; id: string }): Promise<any | ErrorResponse> {
		let url = `v2/basedata/${params.domain}/landing-page/${params.id}`;
		url += stringifyExpands(['sites:min', 'crosslinks', 'min_prices']);

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				this.context.commit('storeCurrentLP', res.data);
				const title = res.data.meta.title.length > 0 ? res.data.meta.title : res.data.city;
				const desc =
					res.data.meta.description ??
					'Finde deinen günstigen Miettransporter an über 30 Standorten. 24/7 Anmieten ✓ Ab 45€/Tag ✓ Unbegrenzt Kilometer und Autobahnvignette inklusive.';

				this.context.commit('addMetaData', {
					key: 'landingpage',
					title: title,
					desc: desc
				});

				const pricing_data = res.data.min_prices;
				const lowest_price = Math.min(
					...Object.values(pricing_data)
						.map((p: any) => p.price_6)
						.filter((price: number) => price > 0)
				);

				// Create JSONlD for current Landing page
				const json_ld = new JsonLD({
					title: `Transporter in ${res.data.name}`,
					desc: desc,
					url: res.data.url,
					country: params.domain.toUpperCase(),
					city: res.data.name,
					postcode: res.data.postcode,
					price: lowest_price
				});

				this.context.commit('storeJsonLd', json_ld);

				return true;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_TRUCK_MIN_PRICES(domain: string): Promise<TruckMinPrices | ErrorResponse> {
		const url = `v2/basedata/${domain}/min-truck-price`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				this.context.commit('storeMinTruckPrice', res.data);
				return this.truck_min_price;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_TRUCK_MIN_PRICES_PER_LOCATION({
		domain,
		site
	}: {
		domain: string;
		site: string;
	}): Promise<any> {
		const url = `v2/basedata/${domain}/min-truck-price?site=${site}`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				this.context.commit('storeMinTruckPricePerLocation', res.data);
				return { status: res.status, data: res.data };
			})
			.catch((err: AxiosError) => {
				// @tood What is this? why are we not using the ErrorResponse.code prop for the status??
				return {
					status: err.response?.status ?? 500,
					data: new ErrorResponse(err.response?.data)
				};
			});
	}

	@VuexAction({ rawError: true })
	async GET_IMPRINT_DATA(domain: string): Promise<string | ErrorResponse> {
		return await $axios
			.get(`v2/basedata/${domain}/imprint`)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}
}

export default getModule(MISC_DATA);
