import { AxiosError, AxiosResponse } from 'axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getModule, Module, VuexModule, VuexMutation, VuexAction } from 'nuxt-property-decorator';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { store } from '@/store';
import { $axios } from '~/utils/api';

import MAP from '@/store/modules/MapModule';

import { Truck, ITruckSlotsFetchParams, TruckProtectionPrices } from '@/types/truck';
import {
	stringifyExpands,
	ErrorResponse,
	ListRequestFilter,
	buildListQuery
} from '@/types/api_helper';

interface StringIndex {
	[key: string]: any;
}

@Module({
	name: 'TRUCKS',
	store, // this basically injects the module in the store dynamically thanks to next line
	dynamic: true,
	stateFactory: true // apparently necessary/better for Nuxt
})
class TRUCKS extends VuexModule {
	list = {} as StringIndex;
	current_truck = new Truck();
	all_trucks = [] as Truck[];

	// This is used by MapTruckCard and MapTrucCarousel to send current week slots status through the truck click GTM event.
	current_week_slots_status = {} as StringIndex;

	// ------------------------------------------------
	// ------------- Mutations ------------------------
	// ------------------------------------------------

	@VuexMutation
	addToTruckList(payload: any[]) {
		payload.forEach((truck: any) => {
			// Safecheck for Site, if truck somehow doesn't have one, don't add it.
			if (!truck.position.site_id) {
				return;
			}
			const key = truck.id + truck.position.site_id;
			this.list[key] = truck;
		});
	}

	@VuexMutation
	clearTruckList() {
		this.list = {};
	}

	@VuexMutation
	storeCurrentTruck(truck: Truck) {
		this.current_truck = truck;
	}

	@VuexMutation
	storeAllTrucks(trucks: Truck[]) {
		this.all_trucks = trucks;
	}

	@VuexMutation
	storeCurrentWeekSlotsStatus(payload: {
		id: string;
		blocked_slots: number;
		available_slots: number;
	}) {
		this.current_week_slots_status[payload.id] = {
			blocked_slots: payload.blocked_slots,
			available_slots: payload.available_slots
		};
	}

	clearCurrentWeekSlotStatus(): void {
		this.current_week_slots_status = {};
	}

	// ------------------------------------------------
	// ------------- Actions --------------------------
	// ------------------------------------------------

	@VuexAction({ rawError: true })
	async GET_TRUCK_DATA(params: { truck_id: string }): Promise<Truck | ErrorResponse> {
		let url = `v2/truck/${params.truck_id}`;
		url += stringifyExpands([
			'model',
			'location:min',
			'site:min',
			'pictures',
			'min-price',
			'site.opening_hours'
		]);

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				this.context.commit('storeCurrentTruck', new Truck(res.data));
				return new Truck(res.data);
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_ALL_TRUCKS_DATA(params: {
		truck_ids: string[];
	}): Promise<Array<Truck | ErrorResponse>> {
		const urls = params.truck_ids.map((id) => {
			let url = `v2/truck/${id}`;
			url += stringifyExpands([
				'model',
				'location:min',
				'site:min',
				'pictures',
				'min-price',
				'site.opening_hours'
			]);
			return url;
		});

		const truckRequests = urls.map((url) =>
			$axios
				.get(url)
				.then((res: AxiosResponse) => new Truck(res.data))
				.catch((err: AxiosError) => new ErrorResponse(err.response?.data))
		);

		const results = await Promise.all(truckRequests);

		this.context.commit(
			'storeAllTrucks',
			results.filter((result) => result instanceof Truck) as Truck[]
		);

		return results;
	}

	@VuexAction({ rawError: true })
	async FETCH_TRUCK_SLOTS(
		paramsArray: ITruckSlotsFetchParams[]
	): Promise<
		Array<{ truck_id: string; slots: number[] } | { truck_id: string; slots: ErrorResponse }>
	> {
		const urls = paramsArray.map((params) => {
			let url = `v2/truck/${params.truck_id}/slots?site_id=${params.truck_location}&time_start=${params.start}&time_end=${params.end}`;
			url += stringifyExpands(['self:index'], false);
			return url;
		});

		const slotRequests = urls.map((url, index) =>
			$axios
				.get(url)
				.then((res: AxiosResponse) => ({
					truck_id: paramsArray[index].truck_id,
					slots: res.data
				}))
				.catch((err: AxiosError) => ({
					truck_id: paramsArray[index].truck_id,
					slots: new ErrorResponse(err.response?.data)
				}))
		);

		const results = await Promise.all(slotRequests);

		return results;
	}

	@VuexAction({ rawError: true })
	async FETCH_TRUCKS_PER_LOCATION(params: {
		city: string;
		start_date: string;
	}): Promise<any | ErrorResponse> {
		const filter = new ListRequestFilter();
		filter.addFilter('state', 'eq', 'A');
		filter.addFilter('truck_site.site.city', 'eq', `${params.city}`);
		filter.addFilter(
			'truck_site.time_start|truck_site.time_end',
			'between',
			`${params.start_date}`
		);

		const expands = ['model', 'site:min', 'location:min'];

		const url = 'v2/truck' + buildListQuery(0, 99, filter, undefined, expands);

		return await $axios
			.get(url, { timeout: 0 })
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async FETCH_TRUCK_LIST(params: {
		min_lat: String;
		max_lat: string;
		min_lng: string;
		max_lng: string;
		start?: string;
		end?: string;
		partner_id?: string;
		sizes?: Array<string>;
		max_price?: string;
		isFilterButtonClick?: boolean;
	}): Promise<Boolean | ErrorResponse> {
		this.context.commit('clearTruckList');

		let url = `v2/truck/area?lat_min=${params.min_lat}&lat_max=${params.max_lat}&lng_min=${params.min_lng}&lng_max=${params.max_lng}`;
		if ((window as any).gtm_helpers) {
			const client_id = (window as any).gtm_helpers.ga_client_id_cookie;
			if (client_id) {
				url += `&client_id=${client_id}`;
			}
		}

		if (
			params.start !== undefined &&
			params.start !== null &&
			params.end !== undefined &&
			params.end !== null
		) {
			url = url.concat(
				`&start=${encodeURIComponent(params.start)}&end=${encodeURIComponent(params.end)}`
			);
		}

		if (params.partner_id) {
			url = url.concat(`&location_partner=${params.partner_id}`);
		}

		if (params.sizes && params.sizes.length > 0) {
			params.sizes.forEach((size, index) => {
				url = url.concat(`&sizes[${index}]=${size}`);
			});
		}

		if (params.max_price && params.max_price.length > 0) {
			url = url.concat(`&max_price=${params.max_price}`);
		}

		return await $axios
			.get(url, { timeout: 0 })
			.then((res: AxiosResponse) => {
				this.context.commit('addToTruckList', res.data);
				MAP.TRUCK_LIST();
				return true;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_RENT_RPICE(params: {
		truck_id: string;
		time_start: Date;
		time_end: Date;
	}): Promise<{ currency: string; amount: number } | ErrorResponse> {
		const url = `v2/truck/${params.truck_id}/price/rent?time_start=${params.time_start.toISOString()}&time_end=${params.time_end.toISOString()}`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_PROTECTION_RPICE(params: {
		truck_id: string;
		time_start: Date;
		time_end: Date;
	}): Promise<TruckProtectionPrices | ErrorResponse> {
		const url = `v2/truck/${params.truck_id}/price/protection?time_start=${params.time_start.toISOString()}&time_end=${params.time_end.toISOString()}`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				return res.data as TruckProtectionPrices;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async GET_PROTECTION_REPAIR_COSTS(bu: string) {
		const url = `/v2/basedata/${bu}/protection-packages`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				return res;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}
}

export default getModule(TRUCKS);
