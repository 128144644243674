// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Module, VuexModule, VuexMutation, getModule, VuexAction } from 'nuxt-property-decorator';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { store } from '@/store';
import { gmapStyle } from '@/_helpers/gmap_style';

import TRUCKS from '@/store/modules/TruckModule';

import { groupBy } from '@/_helpers/misc_helper';

import { ITruckFilterSettings } from '@/types/truck';

import global from '~/mixins/global';

interface StringIndex {
	[key: string]: any;
}

@Module({
	name: 'MAP',
	store, // this basically injects the module in the store dynamically thanks to next line
	dynamic: true,
	stateFactory: true // apparently necessary/better for Nuxt
})
class MAP extends VuexModule {
	hasUnappliedFilters = false;
	cluster_styles = {
		// Tool for changing PNG color tone easily -> https://onlinepngtools.com/change-png-color-tone
		// Tool for changing SVG colors (really good) -> https://deeditor.com/
		// Tool for editing SVG (shapes, transparent bg etc) -> https://pixelied.com/features/svg-editor

		// Will be modified on MapWrapper if on PartnerLocation routes
		main: [
			{
				textColor: 'white',
				textSize: '12',
				anchorText: [0, 0],
				url: `${global.data().staticAssetsPath}/icons/gmap/cluster-orange-1.svg`,
				height: 32,
				width: 32
			},
			{
				textColor: 'white',
				textSize: '14',
				anchorText: [0, 0],
				url: `${global.data().staticAssetsPath}/icons/gmap/cluster-orange-2.svg`,
				height: 46,
				width: 46
			}
		],
		foreign: [
			{
				textColor: 'white',
				textSize: '12',
				anchorText: [0, 0],
				url: `${global.data().staticAssetsPath}/icons/gmap/cluster-pink-1.svg`,
				height: 32,
				width: 32
			},
			{
				textColor: 'white',
				textSize: '14',
				anchorText: [0, 0],
				url: `${global.data().staticAssetsPath}/icons/gmap/cluster-pink-2.svg`,
				height: 46,
				width: 46
			}
		]
	};

	map_style = gmapStyle;

	current_filter = new ITruckFilterSettings();
	all_trucks = {} as any;

	saved_map_position = null as { center: { lat: number; lng: number }; zoom: number } | null;

	expand_filter = false;

	// ------------------------------------------------
	// ------------- Mutations ------------------------
	// ------------------------------------------------

	@VuexMutation
	setUnappliedFilters(value: boolean) {
		this.hasUnappliedFilters = value;
	}

	@VuexMutation
	updateTrucksList(payload: any[]) {
		this.all_trucks = {} as any[];
		// Group trucks by their BUs
		const result = {} as StringIndex;
		// Create a list of BUs containing their respective trucks keyed by their truck_id + site_id
		Array.from(groupBy(payload, (truck: any) => truck.bu)).map((el: [string, any[]]) => {
			const trucks = {} as any;
			el[1].forEach((truck: any) => {
				// Create a truck list with keys being the truck ID + site_id
				const key = truck.id + truck.position.site_id;
				trucks[key] = truck;
			});
			return (result[el[0].toLowerCase()] = trucks);
		});

		this.all_trucks = result;
	}

	@VuexMutation
	updateCurrentFilter(filter: ITruckFilterSettings) {
		this.current_filter = filter;
		this.hasUnappliedFilters = true;
		sessionStorage.setItem('map-filter', JSON.stringify(filter));
	}

	@VuexMutation
	expandFilterState(bool: boolean) {
		this.expand_filter = bool;
	}

	@VuexMutation
	storeMapPosition(payload: typeof this.saved_map_position) {
		this.saved_map_position = payload;
	}

	// ------------------------------------------------
	// ------------- Actions --------------------------
	// ------------------------------------------------

	@VuexAction
	TRUCK_LIST(filter?: ITruckFilterSettings) {
		// Save filter to always apply current one if no filter passed in function
		if (filter) {
			this.context.commit('updateCurrentFilter', JSON.parse(JSON.stringify(filter)));
		}

		const trucks = JSON.parse(JSON.stringify(TRUCKS.list));
		const list_as_array = [];
		for (const i in trucks) {
			list_as_array.push(trucks[i]);
		}

		this.context.commit('updateTrucksList', list_as_array);
	}

	@VuexAction
	STORE_MAP_POSITION(payload: typeof this.saved_map_position) {
		this.context.commit('storeMapPosition', payload);
	}
}

export default getModule(MAP);
